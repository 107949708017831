<template>
  <div>
    <v-card>
      <v-card-title>
        <h1>
          Сертификаты
        </h1>
      </v-card-title>

      <v-tabs v-model="certTpeSel" background-color="transparent" grow>
        <v-tab v-for="item in certTpe" :key="'r' + item.id">
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="certTpeSel">
        <v-tab-item v-for="item in certTpe" :key="item.id">
          <v-card v-if="item.id == 1" flat>
            <v-card-title>
              <v-btn v-if="selected.length > 0" color="error" @click="delStore()" small>
                <v-icon small>fa-trash</v-icon> Удалить
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="addStore()" small> <v-icon small>fa-add</v-icon> Добавить </v-btn>
            </v-card-title>

            <v-card-text>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="data"
                item-key="id"
                show-select
                class="elevation-1"
                dense
              >
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card v-if="item.id == 2" flat>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="certSell()" small> <v-icon small>fa-add</v-icon> Продать </v-btn>
            </v-card-title>

            <v-card-text>
              <!-- <v-data-table
                v-model="selected"
                :headers="headers"
                :items="data"
                item-key="id"
                show-select
                class="elevation-1"
                dense
              >
              </v-data-table> -->
            </v-card-text>
          </v-card>
          <v-card color="basil" flat>
            <v-card-text>---{{ item }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <store_edit v-bind:flgOpen="flgStoreOpen" v-on:close="storeClose"></store_edit>
    <cert_sell v-bind:flgOpen="flgCertSellOpen" v-on:close="flgCertSellClose"></cert_sell>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../../store'
import i18n from '@/i18n'

import store_edit from './store_edit.vue'
import cert_sell from './cert_sell.vue'
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const confirmBox = create(confirm)

import { ref, onMounted, reactive, watch } from '@vue/composition-api'

export default {
  setup() {
    const selected = ref([])
    const certTpe = ref([
      { id: 1, name: 'В наличии' },
      { id: 2, name: 'Проданные' },
      { id: 3, name: 'Просроченные' },
      { id: 4, name: 'Использованные' },
    ])
    const headers = ref([])
    headers.value = [
      {
        text: i18n.t('certificate.num'),
        align: 'start',
        value: 'name',
      },
      { text: i18n.t('certificate.cost'), value: 'cost' },
      { text: i18n.t('certificate.balance'), value: 'balance' },
      { text: i18n.t('certificate.dte_add'), value: 'dte_create_shot' },
      { text: i18n.t('certificate.dte_burn'), value: 'day_limit' },
    ]
    const certTpeSel = ref({ id: 1 })
    const data = ref([])
    watch(certTpeSel, newValue => {
      dataLoad(newValue)
      console.log('🚀 ~ file: store_edit.vue ~ line 85 ~ setup ~ certTpeSel', certTpeSel, newValue)
    })
    const flgStoreOpen = ref('')

    const dataLoad = tpe => {
      store.commit('loadingStartShow', true)

      axios.post(store.state.apiUrl + 'certificate/index', { tpe: tpe }).then(
        response => {
          store.commit('loadingStartShow', false)
          if (response.data) {
            if (!response.data.err) {
              data.value = response.data
              // groups.value = response.data
            }
          }
        },
        err => {
          console.log(err)
        },
      )
    }
    const addStore = () => {
      flgStoreOpen.value = true
    }

    const delStore = () => {
      console.log('confirmBox', confirmBox)
      confirmBox({
        text: i18n.t('certificate.delstoreconfirm'),
        type: 'delete',
        title: i18n.t('certificate.titlestoredel'),
      })
        .transition()
        .then(response => {
          if (response) {
            store.commit('loadingStartShow', true)
            console.log('selected', selected.value)
            axios.post(store.state.apiUrl + 'certificate/delStore/', { ids: selected.value.map(e => e.id) }).then(
              response => {
                store.commit('loadingStartShow', false)
                store.dispatch('setMessage', {
                  type: response.data.err == 1 ? 'error' : 'success',
                  message: i18n.t(response.data.msg),
                })
                if (!response.data.err) {
                  dataLoad(1)
                }
              },
              err => {
                console.log(err)
              },
            )
          }
        })
    }
    onMounted(dataLoad(1))
    const storeClose = prm => {
      flgStoreOpen.value = false
      if (prm == 'reload') {
        dataLoad(1)
      }
    }
    const flgCertSellOpen = ref('')

    const certSell = () => {
      flgCertSellOpen.value = true
    }
    const flgCertSellClose = prm => {
      flgCertSellOpen.value = false
      if (prm == 'reload') {
        dataLoad(2)
      }
    }
    return {
      certTpe,
      certTpeSel,
      addStore,
      storeClose,
      flgStoreOpen,
      data,
      headers,
      selected,
      delStore,
      certSell,
      flgCertSellOpen,
      flgCertSellClose,
    }
  },
  components: {
    store_edit,
    cert_sell,
  },
}
</script>
