<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="700">
    <v-card>
      <v-card-title class="headline">
        {{ $t('certificate.storeedittitle') }}
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid" ref="formBlock">
          <v-text-field
            v-model="form.name"
            :label="$t('certificate.storename')"
            required
            :rules="[v => !!v || $t('all.fieldnoempty')]"
            hint="Пример: K45;K46;K47;1200/1245;SD20/SD40"
            persistent-hint
          ></v-text-field>
          <v-text-field v-model="form.day_limit" :label="$t('certificate.storenday_limit')" required></v-text-field>
          <v-text-field
            v-model="form.balance"
            :label="$t('certificate.balance')"
            required
            :rules="[v => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
          <v-text-field
            v-model="form.cost"
            :label="$t('certificate.cost')"
            required
            :rules="[v => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="$emit('close')">
          {{ $t('all.close') }}
        </v-btn>
        <v-btn color="primary" dark @click.native="save" :disabled="!valid">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, reactive, watch } from '@vue/composition-api'
import axios from 'axios'
import store from '../../store'

export default {
  props: ['flgOpen'],
  setup(props, { emit }) {
    // console.log('🚀 ~ file: store_edit.vue ~ line 51 ~ setup ~ props', props)
    const dialog = ref(false)
    const valid = ref(false)
    const formBlock = ref(null)
    valid.value = false
    const form = reactive({
      name: '',
      cost: '',
      balance: '',
      day_limit: '',
    })
    const save = () => {
      if (valid.value) {
        store.commit('loadingStartShow', true)

        axios.post(store.state.apiUrl + 'certificate/savestore', form).then(
          response => {
            store.commit('loadingStartShow', false)
            store.dispatch('setMessage', {
              type: response.data.err == 1 ? 'error' : 'success',
              message: response.data.msg,
            })
            if (!response.data.err) {
              emit('close', 'reload')
              // groups.value = response.data
            }
          },
          err => {
            console.log(err)
          },
        )
      }
    }
    watch(props, newValue => {
      dialog.value = newValue.flgOpen
      if (formBlock.value) formBlock.value.reset()
      // console.log('🚀 ~ file: store_edit.vue ~ line 85 ~ setup ~ formBlock', formBlock)
    })

    return { dialog, form, save, valid, formBlock }
  },
}
</script>

<style></style>
