<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="700">
    <v-card>
      <v-card-title class="headline">
        {{ $t('certificate.selladdtitle') }}
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid" ref="formBlock">
          <v-autocomplete
            v-model="certificate"
            :loading="isLoadingCert"
            :search-input.sync="searchCert"
            :items="certificates"
            item-text="name"
            item-value="id"
            hide-details
            :label="$t('certificate.name')"
            placeholder="Начните вводить номер сертификата"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  <span v-if="searchCert > 2">Сертификатов с таким названием не найдено</span>
                  <span v-else>Для поиска введите 2 символа</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-text="data.item.name"></v-list-item-title>
                <v-list-item-subtitle>Баланс {{ data.item.balance }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field v-model="form.customer" :label="$t('all.customer')"></v-text-field>
          <v-text-field
            v-model="form.pay_tpe"
            :label="$t('certificate.cost_tpe')"
            required
            :rules="[v => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="$emit('close')">
          {{ $t('all.close') }}
        </v-btn>
        <v-btn color="primary" dark @click.native="save" :disabled="!valid">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, reactive, watch } from '@vue/composition-api'
import axios from 'axios'
import store from '../../store'

export default {
  props: ['flgOpen'],
  setup(props, { emit }) {
    const dialog = ref(false)
    const isLoadingCert = ref(false)
    const searchCert = ref('')
    const certificates = ref([])
    const certificate = ref([])
    const valid = ref(false)
    const formBlock = ref(null)
    valid.value = false
    const form = reactive({
      name: '',
      cost: '',
      balance: '',
      day_limit: '',
    })
    const save = () => {
      if (valid.value) {
        store.commit('loadingStartShow', true)

        axios.post(store.state.apiUrl + 'certificate/savecert', form).then(
          response => {
            store.commit('loadingStartShow', false)
            store.dispatch('setMessage', {
              type: response.data.err == 1 ? 'error' : 'success',
              message: response.data.msg,
            })
            if (!response.data.err) {
              emit('close', 'reload')
              // groups.value = response.data
            }
          },
          err => {
            console.log(err)
          },
        )
      }
    }
    watch(props, newValue => {
      dialog.value = newValue.flgOpen
      if (formBlock.value) formBlock.value.reset()
      console.log('🚀 ~ file: store_edit.vue ~ line 85 ~ setup ~ formBlock', formBlock)
    })
    watch(searchCert, newValue => {
      console.log('🚀 ~ file: cert_sell.vue ~ line 116 ~ setup ~ newValue', typeof newValue)
      if (typeof newValue == 'object' || (typeof newValue == 'string' && newValue.length < 2)) {
        certificates.value = []
      } else {
        isLoadingCert.value = true
        axios
          .post(store.state.apiUrl + 'certificate/SearchStore/', {
            text: newValue,
          })
          .then(
            response => {
              certificates.value = response.data
              console.log('certificates', certificates)
              isLoadingCert.value = false
            },
            err => {
              console.log(err)
            },
          )
      }
    })

    return { dialog, form, save, valid, formBlock, isLoadingCert, certificates, searchCert, certificate }
  },
}
</script>

<style></style>
